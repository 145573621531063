<template>
  <div>
    <UserChatSidebar v-if="hasChatIndividualAddOn" />
    <!-- <ChatListPopup v-if="hasChatIndividualAddOn" /> -->

     <!-- Chat Windows -->
     <!-- <div class="chat-windows-container">
      <popup-chat-window
      v-if="hasChatIndividualAddOn"
        v-for="(chat) in activeChats"
        :key="chat.room"
        :user="chat.user"
        :room="chat.room"
      />
    </div> -->
  </div>
</template>

<script>
// import UserChatSidebar from '@/views/apps/chat/components/UserChatSidebar.vue';
import PopupChatWindow from '@/views/apps/chatv2/components/PopupChatWindow.vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'GlobalComponents',

  components: {
    // UserChatSidebar,
    PopupChatWindow,
    UserChatSidebar: () => import('@/views/apps/chat/components/UserChatSidebar.vue' /* webpackChunkName: "UserChatSidebar" */),
    ChatListPopup: () => import('@/views/apps/chatv2/components/ChatListPopup.vue' /* webpackChunkName: "ChatListPopup" */),
  },
  computed: {
    hasChatIndividualAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].chatIndividual;
    },
    activeChats() {
      return this.$store.state.chat.activeChats;
    },
  },
};
</script>
