<template>
  <div v-if="shouldShow" class="popup-chat-window" :class="{
    minimized: isMinimized,
    'last-active': isLastActive
  }" :style="windowStyle">
    <!-- Chat Header -->
    <div class="chat-header d-flex justify-content-between align-items-center p-1"
      :class="{ 'header-highlight bg-primary': isLastActive }">
      <div class="d-flex align-items-center">
        <user-avatar :user="user" size="32" class="mr-75" />
        <div class="d-flex align-items-center">
          <span class="font-weight-bold">{{ user.name }} {{ user.surname }}</span>
          <!-- Message Counter Badge -->
          <b-badge 
            v-if="isMinimized && messageCount > 0"
            variant="danger"
            pill
            class="message-counter-badge ml-1"
          >
            {{ messageCount }}
          </b-badge>
        </div>
      </div>
      <div :class="{ 'chat-controls': isLastActive }">
        <b-button variant="link" class="p-0 mr-1" @click="minimize">
          <feather-icon icon="MinusIcon" size="16" />
        </b-button>
        <b-button variant="link" class="p-0" @click="close">
          <feather-icon icon="XIcon" size="16" />
        </b-button>
      </div>
    </div>

    <!-- Chat Content -->
    <div v-show="!isMinimized" class="chat-content">
      <chat-component :room="room" :user="user" :is-popup="true" @new-message="handleNewMessage" />
    </div>
  </div>
</template>

<script>
import UserAvatar from '@core/components/user/UserAvatar.vue';
import ChatComponent from './chatComponent.vue';
import { CHAT_ACTIONS } from '../../chat/constants/chat-store-constants';

export default {
  name: 'PopupChatWindow',
  components: {
    UserAvatar,
    ChatComponent
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    room: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isMinimized: false,
      screenWidth: window.innerWidth,
      messageCount: 0
    };
  },
  computed: {
    windowStyle() {
      const baseWidth = this.screenWidth <= 480 ? '100%' : '330px';
      let rightPosition;
      if (this.screenWidth <= 480) {
        rightPosition = 0;
      } else {
        const chatListWidth = 280;
        const spacing = 20;
        const windowIndex = this.$store.state.chat.activeChats
          .findIndex(chat => chat.room === this.room);

        rightPosition = chatListWidth + spacing + (windowIndex * (parseInt(baseWidth, 10) + spacing));
      }

      return {
        right: `${rightPosition}px`,
        width: baseWidth,
      };
    },

    shouldShow() {
      const maxWindows = this.calculateMaxWindows();
      const windowIndex = this.$store.state.chat.activeChats
        .findIndex(chat => chat.room === this.room);

      return windowIndex < maxWindows;
    },

    isLastActive() {
      const { activeChat } = this.$store.state.chat;
      return activeChat === this.room;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    minimize() {
      this.isMinimized = !this.isMinimized;
      if (!this.isMinimized) {
        // Reset message count when maximizing
        this.messageCount = 0;
      }
    },
    close() {
      this.$store.dispatch(CHAT_ACTIONS.closeChatWindow, this.room);
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    calculateMaxWindows() {
      const width = this.screenWidth;
      if (width <= 480) return 1;
      if (width <= 768) return 2;
      if (width <= 1024) return 3;
      return 4;
    },
    handleNewMessage() {
      if (this.isMinimized) {
        this.messageCount++;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.popup-chat-window {
  position: fixed;
  bottom: 0;
  height: 455px;
  background: white;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 998;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  margin-inline: 10px;

  &.minimized {
    height: 48px;
  }

  &.last-active {
    z-index: 999;
    box-shadow: 0 0 15px rgba(13, 110, 253, 0.15);
  }

  .chat-header {
    background: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    border-radius: 8px 8px 0 0;
    height: 48px;
    cursor: pointer;
    transition: all 0.3s ease;

    &.header-highlight {
      background: #e7f5ff;
      border-bottom: 2px solid #ffffff;

      .font-weight-bold {
        font-weight: 800;
        color: #e7f5ff;
      }
    }

    .message-counter-badge {
      font-size: 12px;
      min-width: 20px;
      height: 20px;
      padding: 0 6px;
      line-height: 20px;
      text-align: center;
    }
  }

  .chat-content {
    flex: 1;
    overflow: hidden;
  }

  .chat-controls {
    button {
      color: white;
    }
  }

  @media (max-width: 768px) {
    &:not(.minimized) {
      height: 400px;
    }

    &.minimized {
      width: 200px;
    }
  }

  @media (max-width: 480px) {
    left: 0;
    right: 0 !important; // Override inline style
    width: 100% !important; // Override inline style

    &.minimized {
      width: 150px !important; // Override inline style
      right: 0 !important;
      left: auto !important;
    }
  }
}
</style>